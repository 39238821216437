import axios from 'axios';
import { Auth } from 'aws-amplify';

const ApiCall = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    withCredentials: false
});

ApiCall.interceptors.request.use(
    async (config) => {
        await Auth.currentAuthenticatedUser()
            .then(res => {
                config.headers["Authorization"] = "Bearer " + res?.signInUserSession?.idToken?.jwtToken;
            })
            .catch(() => {
                config.headers["Authorization"] = "Bearer ";
            })
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)


export { ApiCall };
