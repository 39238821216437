import React from "react";
import App from "./App";
import { AuthProvider } from 'contexts/AuthContext';
import { createRoot } from "react-dom/client";
import "./index.css";

createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>,
)
