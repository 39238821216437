import { Fragment, useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { toAbsoluteUrl, classNames } from "utils/helpers";
import { NavLink } from "react-router-dom";
import useAuth from "hooks/useAuth";

const navigation = [
  { name: 'My Schools', href: '/' },
  { name: 'Create New School', href: '/create-school' },
  { name: 'Settings', href: '/settings' },
  { name: 'Logout', href: '/logout' }
]

const userNavigation = [
  { name: 'Settings', href: '/settings' },
  { name: 'Logout', href: '/logout' },
]

const date = new Date();

const PrimaryLayout = ({ children }) => {
  const { user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={toAbsoluteUrl('/media/logo.png')}
                      alt="Logo"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? 'bg-gray-50 text-primary'
                                      : 'text-gray-700 hover:text-primary hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-10'
                                  )
                                }
                              >
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-0 lg:flex lg:w-[192px] lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#FAFAFA] px-6 pb-4 pt-10">
          <div className="flex h-16 shrink-0 items-center">
            {/* <img
                            className="h-8 w-auto"
                            src={toAbsoluteUrl('/media/logo.png')}
                            alt="Logo"
                        /> */}
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1 flex flex-col gap-4">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? 'bg-white text-primary px-3 py-2 rounded-[10px]'
                              : 'text-gray-600 hover:text-primary px-3 py-2 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md text-sm leading-10'
                          )
                        }
                      >
                        {({ isActive }) => (
                          isActive ? (
                            <div className="flex justify-between w-full items-center">
                              {item.name}
                              <span className={isActive && 'bg-primary w-2 h-2 rounded-full'}></span>
                            </div>
                          ) : (
                            item.name
                          )
                        )}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="">
        <div className="sticky top-0 z-50 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-md sm:gap-x-6 sm:px-6 lg:px-8 py-10">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>


          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="flex flex-1 justify-between items-center gap-x-4 lg:gap-x-6">
              {/* Profile dropdown */}
              <img
                className="h-8 w-auto"
                src={toAbsoluteUrl('/media/logo.png')}
                alt="Logo"
              />
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 border border-[#EDEEEF]">
                    {!hasError && user?.attributes?.picture ? (
                      <img className="w-full h-full object-cover" src={user?.attributes?.picture} alt="avatar" onError={() => setHasError(true)} />
                    ) : (
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                  </span>
                  <span className="hidden lg:flex lg:items-center">
                    <span className="ml-4 text-sm leading-6 text-gray-900" aria-hidden="true">
                      {user?.attributes?.name}
                    </span>
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="pt-10">
          <div className="px-4 sm:px-6 lg:pl-56 lg:pr-8">{children}</div>
        </main>
        <footer className="sticky bottom-0 bg-white px-4 sm:px-6 lg:pl-56 lg:pr-8 mt-32 p-5 border-t border-t-gray-200">
          <span className="text-gray-400 text-sm">Eduqat  -   © {date.getFullYear()} All Rights Reserved</span>
        </footer>
      </div>
    </div>
  )
}

export default PrimaryLayout