import { createContext, useState, useEffect } from "react";
import { Fetcher } from "utils/Fetcher"; // Fetcher data using Monorepo API

export const CreateSchoolContext = createContext();

export const CreateSchoolProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
    type: "",
  });

  // Initial state for dataSchool
  const initialDataSchool = {
    siteId: "",
    siteTitle: "",
    type: "creator", // "creator" || "internal-training"
    currency: "",
    language: "id-ID",
    timezone: "",
    country: "",
    state_code: "",
    city: "",
    plan: "free",
    additionalInfo: {
      jobTitle: "", // identity your self
      haveTeach: "", // have teach online course
      sellingCourseRevenue: "", // selling online course
      audienceSize: "", // audience
    },
  };

  const [dataSchool, setDataSchool] = useState(() => {
    const storedData = localStorage.getItem("dataSchool");
    return storedData ? JSON.parse(storedData) : initialDataSchool;
  });

  // Save dataSchool to localStorage
  useEffect(() => {
    localStorage.setItem("dataSchool", JSON.stringify(dataSchool));
  }, [dataSchool]);

  const handleCreateSchool = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await Fetcher.post("/sso/sites", dataSchool);

      setNotification({
        show: true,
        title: "School created successfully.",
        type: "success",
      });
      if (response.data?.redirectUrl) {
        localStorage.removeItem('dataSchool');
        window.location.replace(response.data.redirectUrl);
      }
    } catch (error) {
      setNotification({
        show: true,
        title: "Failed to create school",
        message: error.response?.data?.message || "An unexpected error occurred.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CreateSchoolContext.Provider
      value={{
        dataSchool,
        setDataSchool,
        notification,
        setNotification,
        isLoading,
        handleCreateSchool,
      }}
    >
      {children}
    </CreateSchoolContext.Provider>
  );
};
