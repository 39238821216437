import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { toAbsoluteUrl, classNames } from "utils/helpers";

import useAuth from "hooks/useAuth";
import { Outlet } from "react-router-dom";

const userNavigation = [
  { name: "Settings", href: "/settings" },
  { name: "Logout", href: "/logout" },
];

const SecondaryLayout = ({ children }) => {
  const { user } = useAuth();
  const [hasError, setHasError] = useState(false);

  return (
    <div>
      <div className="">
        <div className="fixed w-full top-0 z-50 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-md sm:gap-x-6 sm:px-6 lg:px-8 py-10">
          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="flex flex-1 justify-between items-center gap-x-4 lg:gap-x-6">
              {/* Profile dropdown */}
              <img className="h-8 w-auto" src={toAbsoluteUrl("/media/logo.png")} alt="Logo" />
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 border border-[#EDEEEF]">
                    {!hasError && user?.attributes?.picture ? (
                      <img
                        className="w-full h-full object-cover"
                        src={user?.attributes?.picture}
                        alt="avatar"
                        onError={() => setHasError(true)}
                      />
                    ) : (
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                  </span>
                  <span className="hidden lg:flex lg:items-center">
                    <span className="ml-4 text-sm leading-6 text-gray-900" aria-hidden="true">
                      {user?.attributes?.name}
                    </span>
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="pt-28 bg-light-gray min-h-screen">
          <div className="container mx-auto px-4 sm:px-6"><Outlet /></div>
        </main>
      </div>
    </div>
  );
};

export default SecondaryLayout;
