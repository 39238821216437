// Using Monorepo API

import axios from 'axios';
import { Auth } from 'aws-amplify';

const Fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_MONOREPO_URL,
    withCredentials: false
});

Fetcher.interceptors.request.use(
    async (config) => {
        await Auth.currentAuthenticatedUser()
            .then(res => {
                config.headers["Authorization"] = "Bearer " + res?.signInUserSession?.idToken?.jwtToken;
            })
            .catch(() => {
                config.headers["Authorization"] = "Bearer ";
            })
        return config
    },
    (error) => {
        return Promise.reject(error.response);
    }
)


export { Fetcher };
