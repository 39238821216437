const Spinner = ({ className = '' }) => {
    return (
        <div className={`w-full h-full flex justify-center items-center ${className}`}>
            <div className="animate-spin inline-block w-8 h-8 border-[2px] border-primary border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Spinner